import { waitForPreviousPromise } from '../../main/services/wait-for-previous-promise'

const DECAY_FACTOR = 1.25

type IntervalOrGetter = number | (() => number)
type IntervalOptions = {
  interval: IntervalOrGetter
  /**
   * Gradually lower the refetch interval by `decayFactor` when the window is not visible
   */
  decay?: boolean
  /**
   * Set factor of decay
   * @default 1.25
   */
  decayFactor?: number
  /**
   * The maximum interval when decaying the refresh interval
   * @default QuotePollingIntervalInMs based on free/elite
   */
  maxDecayInterval?: IntervalOrGetter
}
type IntervalCallback = () => void | Promise<void>

function getCurrentDateInMs() {
  return new Date().valueOf()
}

function getIsDocumentVisible() {
  return document.visibilityState === 'visible' || document.hidden === false
}

/**
 * Invoke a callback when page visibility changes. Turns the callback into a promise
 * and waits for the previous promise to finish before invoking it again
 */
export function notifyOnVisibilityChange(callback: (visible: boolean) => void | Promise<void>) {
  const promisifiedCallback = waitForPreviousPromise(() => callback(getIsDocumentVisible()))

  document.addEventListener('visibilitychange', promisifiedCallback)

  return {
    callback: promisifiedCallback,
    unsubscribe: () => {
      document.removeEventListener('visibilitychange', promisifiedCallback)
    },
  }
}

function getInterval(interval: IntervalOrGetter) {
  if (typeof interval === 'number') return () => interval
  return interval
}

/**
 * Calls callback based on interval and document visibility. Returns unsubscribe function.
 * How it works:
 * - document is hidden: interval cleared (or if options.decay is enabled it decays slowly)
 * - document visible: interval set-up with remaining time from last interval. Call immediately if interval elapsed
 */
export function intervalCallbackOnWindowVisible(initOptions: IntervalOptions, callback: IntervalCallback) {
  const options = {
    interval: getInterval(initOptions.interval),
    decay: initOptions.decay,
    decayFactor: initOptions.decayFactor ?? DECAY_FACTOR,
    maxDecayInterval: getInterval(initOptions.maxDecayInterval ?? 0),
  }
  let refreshPromise: Promise<void> | void | null = null
  let nextRefresh = getCurrentDateInMs() + options.interval()
  let refreshTimeout: number | null = null

  async function refresh() {
    const currentDateMs = getCurrentDateInMs()

    // Call callback asynchronously
    if (refreshPromise === null || currentDateMs < nextRefresh) refreshPromise = callback()
    nextRefresh = currentDateMs + options.interval()
    await refreshPromise
    refreshPromise = null

    // Queue next refresh
    if (getIsDocumentVisible()) {
      refreshTimeout = window.setTimeout(refresh, Math.max(0, nextRefresh - getCurrentDateInMs()))
    }
  }

  // When the window is not visible, slowly lower the refresh interval, but keep it under a minute
  function decayRefreshInterval() {
    let decayedInterval = options.interval()

    async function decay() {
      // Call the getter again, interval might have changed (eg. ah)
      const maxTimeout = options.maxDecayInterval()

      await refresh()
      decayedInterval = Math.round(decayedInterval * options.decayFactor)

      if (decayedInterval > maxTimeout) decayedInterval = maxTimeout

      refreshTimeout = window.setTimeout(decay, decayedInterval)
    }

    refreshTimeout = window.setTimeout(decay, decayedInterval)
  }

  /**
   * Handle the visibility change event, compute callback remaining time or call immediately
   */
  function handleVisibilityChange() {
    if (refreshTimeout) clearTimeout(refreshTimeout)

    if (getIsDocumentVisible()) {
      const currentDateMs = getCurrentDateInMs()
      // Refresh if the document is stale, otherwise set a timeout to refresh later
      if (nextRefresh <= currentDateMs) {
        refresh()
      } else {
        refreshTimeout = window.setTimeout(refresh, nextRefresh - currentDateMs)
      }
    } else if (options.decay && options.maxDecayInterval() > 0) {
      decayRefreshInterval()
    }
  }

  if (getIsDocumentVisible()) {
    refreshTimeout = window.setTimeout(refresh, options.interval())
  }

  document.addEventListener('visibilitychange', handleVisibilityChange)

  return () => {
    if (refreshTimeout) clearTimeout(refreshTimeout)
    document.removeEventListener('visibilitychange', handleVisibilityChange)
  }
}
