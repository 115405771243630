import { SelectRenderer, SelectRendererItemProps } from '@ariakit/react-core/select/select-renderer'
import classNames from 'classnames'
import * as React from 'react'
import { useInRouterContext } from 'react-router-dom'

import { MenuGroup } from '../MenuGroup'
import { ButtonRoundingType } from '../button'
import { SelectOption } from './SelectOption'
import { SelectItem, SelectItemGroup } from './types'
import { filterSelectOptions, getSelectItemProps, isSelectItem } from './utils'

interface SelectItemsVirtualizedProps {
  hasGroups: boolean
  itemRenderer: (item: SelectItem<unknown>, providedProps?: any) => React.ReactNode
}

function SelectItemsVirtualized(props: SelectItemsVirtualizedProps) {
  return (
    <SelectRenderer id="select-popover-list" itemSize={props.hasGroups ? undefined : 28} overscan={8}>
      {({ value: groupOritem, index, label, ...restProps }: any) => {
        if (isSelectItem(groupOritem)) {
          return props.itemRenderer(groupOritem, { key: index, ...restProps })
        }

        return (
          <SelectRenderer
            key={index}
            {...restProps}
            // Add space for group label
            paddingStart={label ? 20 : 4}
            // Add small padding between groups
            paddingEnd={4}
            // Button size
            itemSize={28}
            render={(rendererProps) => (
              <MenuGroup {...rendererProps} virtualized label={label}>
                {rendererProps.children}
              </MenuGroup>
            )}
          >
            {({ value, index, ...restItem }: SelectRendererItemProps<{ value: SelectItem<unknown> }>) =>
              props.itemRenderer(value, { key: index, ...restItem })
            }
          </SelectRenderer>
        )
      }}
    </SelectRenderer>
  )
}

interface SelectItemsProps<ItemType> {
  searchValue: string
  items?: ItemType[]
  rounding?: ButtonRoundingType
  parentTestId?: string
}

export function SelectItems<ItemType extends SelectItem<unknown> | SelectItemGroup<unknown>>({
  items,
  rounding,
  searchValue,
  parentTestId,
  virtualized,
}: SelectItemsProps<ItemType> & { virtualized?: boolean }) {
  const isInRouterContext = useInRouterContext()
  const filteredItems = React.useMemo(() => filterSelectOptions(items, searchValue), [items, searchValue])

  const getItem = React.useCallback(
    (innerItem: SelectItem<unknown>, providedProps?: any) => (
      <SelectOption
        {...providedProps}
        key={providedProps.key}
        value={innerItem}
        // Make sure the rounding is off when trigger rounding is off
        rounding={rounding === 'none' ? 'none' : undefined}
        leftContent={innerItem.icon}
        // Handle link options
        {...getSelectItemProps(innerItem, isInRouterContext)}
        // Add test ids
        data-testid={parentTestId ? `${parentTestId}-${innerItem.value}` : `select-option-${innerItem.value}`}
        className="w-full"
        contentClass={classNames({ truncate: virtualized })}
        subtitle={innerItem.subtitle}
        disabled={innerItem.disabled}
      >
        {innerItem.label}
      </SelectOption>
    ),
    [isInRouterContext, parentTestId, rounding, virtualized]
  )

  if (!filteredItems.length) {
    return <div className="py-1 text-center text-2xs">Nothing to select</div>
  }

  if (virtualized) {
    const hasGroups = isSelectItem(items?.[0]) === false
    return <SelectItemsVirtualized hasGroups={hasGroups} itemRenderer={getItem} />
  }

  return (
    <>
      {filteredItems.map((item, index) => {
        if (isSelectItem(item)) {
          return getItem(item, { key: index })
        }

        return (
          <MenuGroup
            key={index}
            label={item.label}
            hasBorder={item.hasBorder}
            className={classNames(item.className, 'mt-1 first:mt-0 first:pt-0')}
          >
            {item.items.map((item, itemIndex) => getItem(item, { key: itemIndex }))}
          </MenuGroup>
        )
      })}
    </>
  )
}
